import WhiteLogo from "../../assets/Logo.png";
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

function Footer() {
  const openWhatsApp = () => {
    const phoneNumber = "201018146104"; // Your phone number
    const message = "More Details About Sila Resort please!"; // Optional predefined message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open WhatsApp in a new tab
    window.open(url, "_blank");
  };

  const openEmailClient = () => {
    const email = "sales@sila-resort.com"; // Your email address
    const subject = "Inquiry"; // Optional predefined subject
    const body = "Hello, I would like to know more about..."; // Optional predefined message body
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open email client
    window.location.href = mailtoLink;
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col footer__logo">
            <img src={WhiteLogo} alt="Logo" />
            <p>
              At our company, we strive to revolutionize the real estate
              industry by offering innovative solutions and exceptional customer
              service. Our goal is to make the process of buying, selling, or
              renting a property as seamless and stress-free as possible for our
              clients.
            </p>

            <div className="sotial__media">
              {/* Facebook */}
              <a
                href="https://www.facebook.com/profile.php?id=61563945441361"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={20} color="#fff" />
              </a>

              {/* WhatsApp */}
              <a onClick={openWhatsApp} style={{ cursor: "pointer" }}>
                <FaWhatsapp size={23} color="#fff" />
              </a>

              {/* Email */}
              <a onClick={openEmailClient} style={{ cursor: "pointer" }}>
                <MdOutlineMail size={23} color="#fff" />
              </a>
            </div>
          </div>

          {/* Home and Unit */}
          <div className="col">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="nav-link"
                  onClick={() =>
                    window.scrollTo({
                      top: document.getElementById("Units").offsetTop,
                      behavior: "smooth",
                    })
                  }
                >
                  Units
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <ul>
              <li>
                <a href="https://maps.google.com/?q=28.05927,34.4312504">
                  <i className="fas fa-map-marker-alt">
                    <IoLocationSharp
                      size={25}
                      color="#fff"
                      className="Footer-Location"
                    />
                    Al Salam Road, In front of Steigenberger, Sharm El Sheikh
                  </i>
                </a>
              </li>
              <li className="footer-call">
                <p>
                  <a
                    href="tel:+201018146104"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <i className="fas fa-phone-alt"></i> +201018146104
                  </a>
                </p>
                <p>
                  <a
                    href="tel:+201000330918"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <i className="fas fa-phone-alt"></i> +201000330918
                  </a>
                </p>
                <p>
                  <a
                    href="tel:+201222732566"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <i className="fas fa-phone-alt"></i> +201222732566
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>© Copyright Medih 2023 All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;