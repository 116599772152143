import twoBedromMaster1 from "../../assets/twoBedromMaster1.png";
import twoBedromMaster2 from "../../assets/twoBedromMaster2.png";
import twoBedromMaster3 from "../../assets/twoBedromMaster3.png";
import twoBedromMaster4 from "../../assets/twoBedromMaster4.png";
import twoBedromMaster5 from "../../assets/twoBedromMaster5.png";
import twoBedromMaster6 from "../../assets/twoBedromMaster6.png";
import twoBedromMaster7 from "../../assets/twoBedromMaster7.png";
import twoBedromMaster8 from "../../assets/twoBedromMaster8.png";
import twoBedromMaster9 from "../../assets/twoBedromMaster9.png";
import twoBedromMaster10 from "../../assets/twoBedromMaster10.png";
import twoBedromMaster11 from "../../assets/twoBedromMaster11.png";
import twoBedromMaster12 from "../../assets/twoBedromMaster12.png";
import twoBedromMaster13 from "../../assets/twoBedromMaster13.png";
import twoBedromMaster14 from "../../assets/twoBedromMaster14.png";
import twoBedromMainBath1 from "../../assets/twoBedromMainBath1.png";
import twoBedromMainBath2 from "../../assets/twoBedromMainBath2.png";
import twoBedromMainBath3 from "../../assets/twoBedromMainBath3.png";
import twoBedromMainBath4 from "../../assets/twoBedromMainBath4.png";
import twoBedromMasterBedroom1 from "../../assets/twoBedromMasterBedroom1.png";
import twoBedromMasterBedroom2 from "../../assets/twoBedromMasterBedroom2.png";
import twoBedromMasterBedroom3 from "../../assets/twoBedromMasterBedroom3.png";
import twoBedromMasterBedroom4 from "../../assets/twoBedromMasterBedroom4.png";
import twoBedromMasterBedroom5 from "../../assets/twoBedromMasterBedroom5.png";
import twoBedromMasterBath1 from "../../assets/twoBedromMasterBath1.png";
import twoBedromMasterBath2 from "../../assets/twoBedromMasterBath2.png";
import twoBedromMasterBath3 from "../../assets/twoBedromMasterBath3.png";
import twoBedromMasterBath4 from "../../assets/twoBedromMasterBath4.png";
import twoBedromChildRom1 from "../../assets/twoBedromChildRom1.png";
import twoBedromChildRom2 from "../../assets/twoBedromChildRom2.png";
import twoBedromChildRom3 from "../../assets/twoBedromChildRom3.png";
import twoBedromChildRom5 from "../../assets/twoBedromChildRom5.png";
import twoBedromChildRom4 from "../../assets/twoBedromChildRom4.png";
import twoBedromChildRom6 from "../../assets/twoBedromChildRom6.png";
import twoBedromChildRom7 from "../../assets/twoBedromChildRom7.png";
import twoBedroomFile from "../../assets/TwoBedroom.pdf";


// Constants
const meterPrice = 32000;
const area = 115;
const discountRate = 0.20;
const downPaymentRate = 0.20;
const numberOfQuarters = 36 / 3; // Total quarters in a 3-year period

// Total Price without any discounts
const totalPrice = area * meterPrice;

// Total Cash Price with a 20% Discount
const discountedPrice = totalPrice * (1 - discountRate);

// Total Installment Price (full price without discount)
const installmentPrice = totalPrice;

// Down Payment in Case of Installment (20%)
const downPayment = installmentPrice * downPaymentRate;

// Remaining Balance after Down Payment
const remainingBalance = installmentPrice - downPayment;

// Quarterly Installment Price
const quarterlyInstallment = remainingBalance / numberOfQuarters;

// console.log("Total Cash Price with Discount:", discountedPrice, "EGP");
// console.log("Total Installment Price:", installmentPrice, "EGP");
// console.log("Down Payment (20%):", downPayment, "EGP");
// console.log("Quarterly Installment Price:", quarterlyInstallment, "EGP");






function TwoBedroom() {
  return (
    <>
      {/* Two bedroom  */}
      <h1 className="PaymentUnitInfo__h1 secondary-color flex__center">
        Two Bedroom
      </h1>
      {/* carousel slider */}

      <div
        id="carouselExampleInterval"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner carusel__img">
          <div className="carousel-item active" data-bs-interval="5000">
            <img src={twoBedromMaster1} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <img src={twoBedromMaster2} className="d-block w-100" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={twoBedromMaster3} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster4} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster5} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster6} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster7} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster8} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster9} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster10} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster11} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster12} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster13} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMaster14} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMainBath1} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMainBath2} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMainBath3} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromMainBath4} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBedroom1}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBedroom2}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBedroom3}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBedroom4}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBedroom5}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBath1}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBath2}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBath3}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img
              src={twoBedromMasterBath4}
              className="d-block w-100"
              alt="..."
            />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom1} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom2} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom3} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom4} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom5} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom6} className="d-block w-100" alt="..." />
          </div>

          <div className="carousel-item">
            <img src={twoBedromChildRom7} className="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* dowload file */}

      <div className="OneBedroom__downloadFile">
        <a
          className="flex__center"
          href={twoBedroomFile}
          download="Sila Two Bedroom"
          target="_blank"
          rel="noreferrer"
        >
          <button type="button" className="btn btn-primary Downolad-btn">
            Download File
          </button>
        </a>
      </div>

      {/* info */}
      <div className="PaymentUnitInfo__oneBedroom-info">
        <h3 className="flex__center secondary-color">Info</h3>
        <div className="info-grid">
          <div className="info-item">
            <span className="main-span">Unit Area:</span> {area} Square Meter
          </div>
          <div className="info-item">
            <span className="main-span">Bedrooms:</span> 2 Bedroom
          </div>
          <div className="info-item">
            <span className="main-span">Price:</span> {meterPrice} EGP / Meter
          </div>
          <div className="info-item">
            <span className="main-span">Payment Method:</span> Cash / Installment
          </div>
          <div className="info-item">
            <span className="main-span">Unit Total Price:</span> {totalPrice} EGP
          </div>
          <div className="info-item">
            <span className="main-span">Unit Cash Price:</span> {discountedPrice} EGP
          </div>
          <div className="info-item">
            <span className="main-span">Down Payment:</span> {downPayment} EGP
          </div>
          <div className="info-item">
            <span className="main-span">Unit Quarterly Installment Price:</span> {Math.round(quarterlyInstallment.toFixed(2))} EGP
          </div>
        </div>
      </div>
    </>
  );
}

export default TwoBedroom;
