import oneBedroom from "../../assets/oneBedroom.png";
import oneBedroomReception1 from "../../assets/oneBedroomReception1.png";
import oneBedroomReception2 from "../../assets/oneBedroomReception2.png";
import oneBedroomReception3 from "../../assets/oneBedroomReception3.png";
import oneBedroomReception4 from "../../assets/oneBedroomReception4.png";
import oneBedroomReception5 from "../../assets/oneBedroomReception5.png";
import oneBedroomReception6 from "../../assets/oneBedroomReception6.png";
import oneBedroomReception7 from "../../assets/oneBedroomReception7.png";
import oneBedroomReception8 from "../../assets/oneBedroomReception8.png";
import oneBedroomReception9 from "../../assets/oneBedroomReception9.png";
import mainBathroom1 from "../../assets/mainBathroom1.png";
import mainBathroom2 from "../../assets/mainBathroom2.png";
import mainBathroom3 from "../../assets/mainBathroom3.png";
import mainBathroom4 from "../../assets/mainBathroom4.png";
import masterBedroom1 from "../../assets/masterBedroom1.png";
import masterBedroom2 from "../../assets/masterBedroom2.png";
import masterBedroom3 from "../../assets/masterBedroom3.png";
import masterBedroom4 from "../../assets/masterBedroom4.png";
import masterBedroom5 from "../../assets/masterBedroom5.png";
import masterBedroom6 from "../../assets/masterBedroom6.png";
import ExampleDoc from "../../assets/oneBedroom.pdf";

// Constants
const meterPrice = 32000;
const area = 75;
const discountRate = 0.20;
const downPaymentRate = 0.20;
const numberOfQuarters = 36 / 3; // Total quarters in a 3-year period

// Total Price without any discounts
const totalPrice = area * meterPrice;

// Total Cash Price with a 20% Discount
const discountedPrice = totalPrice * (1 - discountRate);

// Total Installment Price (full price without discount)
const installmentPrice = totalPrice;

// Down Payment in Case of Installment (20%)
const downPayment = installmentPrice * downPaymentRate;

// Remaining Balance after Down Payment
const remainingBalance = installmentPrice - downPayment;

// Quarterly Installment Price
const quarterlyInstallment = remainingBalance / numberOfQuarters;

// console.log("Total Cash Price with Discount:", discountedPrice, "EGP");
// console.log("Total Installment Price:", installmentPrice, "EGP");
// console.log("Down Payment (20%):", downPayment, "EGP");
// console.log("Quarterly Installment Price:", quarterlyInstallment, "EGP");

function OneBedroom() {
  return (
    <>
      {/* Header for the one-bedroom section */}
      <h1 className="PaymentUnitInfo__h1 secondary-color flex__center">
        One Bedroom
      </h1>

      {/* Carousel Slider */}
      <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner carusel__img">
          {[oneBedroom, oneBedroomReception1, oneBedroomReception2, oneBedroomReception3, oneBedroomReception4, oneBedroomReception5, oneBedroomReception6, oneBedroomReception7, oneBedroomReception8, oneBedroomReception9, mainBathroom1, mainBathroom2, mainBathroom3, mainBathroom4, masterBedroom1, masterBedroom2, masterBedroom3, masterBedroom4, masterBedroom5, masterBedroom6].map((img, index) => (
            <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index} data-bs-interval="3500">
              <img src={img} className="d-block w-100" alt={`img-${index}`} />
            </div>
          ))}
        </div>
        <button className="carousel-control-prev carousel__color" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next carousel__color" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Download File Section */}
      <div className="OneBedroom__downloadFile">
        <a className="flex__center" href={ExampleDoc} download="Sila One Bedroom" target="_blank" rel="noreferrer">
          <button type="button" className="btn btn-primary Downolad-btn">Download File</button>
        </a>
      </div>

      {/* Info Section */}
      <div className="PaymentUnitInfo__oneBedroom-info">
        <h3 className="flex__center secondary-color">Info</h3>
        <div className="info-grid">
          <div className="info-item">
            <span className="main-span">Unit Area:</span> 75 Square Meter
          </div>
          <div className="info-item">
            <span className="main-span">Bedrooms:</span> 1 Bedroom
          </div>
          <div className="info-item">
            <span className="main-span">Price:</span> {meterPrice} EGP / Meter
          </div>
          <div className="info-item">
            <span className="main-span">Payment Method:</span> Cash / Installment
          </div>
          <div className="info-item">
            <span className="main-span">Unit Total Price:</span> {totalPrice} EGP
          </div>
          <div className="info-item">
            <span className="main-span">Unit Cash Price:</span> {discountedPrice} EGP
          </div>
          <div className="info-item">
            <span className="main-span">Down Payment:</span> {downPayment} EGP
          </div>
          <div className="info-item">
            <span className="main-span">Unit Quarterly Installment Price:</span> {Math.round(quarterlyInstallment.toFixed(2))} EGP
          </div>
        </div>
      </div>
    </>
  );
}

export default OneBedroom;
